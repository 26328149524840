export const languages = [
  {
    code: "en",
    name: "English",
    abreviation: "EN",
    flag: "🇬🇧",
  },
  {
    code: "fr",
    name: "Français",
    abreviation: "FR",
    flag: "🇫🇷",
  },
];