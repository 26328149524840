export const colorSkillsIcon = [
  "#3998D0",
  "#2EB6AF",
  "#A9BD33",
  "#F89930",
  "#F45623",
  "#D62E32",
  "#EB586E",
  "#9952CF",
  "#12A4C9",
  "#B9567C",
  "#31B6E7",
  "#F57D20",
  "#505050",
  "#FF5A5F",
  "#6A5ACD",
  "#E60023",
  "#00A98F",
  "#E9546B",
  "#C46210",
  "#C72C41",
  "#777777",
  "#3A3A3A",
  "#FF6666",
  "#9370DB",
  "#DC143C",
  "#7B68EE",
  "#FF0000",
  "#FF4500",
  "#FFA500",
  "#00CED1",
  "#1E90FF",
  "#0000FF",
  "#8A2BE2",
  "#EE82EE",
  "#FF00FF",
  "#FF1493",
  "#1C1C1C",
  "#707070",
  "#FF69B4",
  "#4169E1",
  "#9400D3",
  "#FF6347",
];

export const getRandomColor = () => {
  return colorSkillsIcon[Math.floor(Math.random() * colorSkillsIcon.length)];
};
